<template>
  <b-card title="Audits">
    <b-modal
      id="audit-details"
      title="Audit Details"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      size="xl"
      hide-footer
    >
      <b-row>
        <!-- <b-col
          cols="12"
          sm="12"
          md="12"
          lg="12"
        >
          <h2>Employee Detils</h2>
          <table class="table">
            <tr>
              <td>Name</td>
              <td>
                xxxxxxxx
              </td>
            </tr>
          </table>
        </b-col> -->
        <b-col
          cols="12"
          sm="12"
          md="12"
          lg="12"
        >
          <h2>Audit Detils</h2>
          <table class="table">
            <tr>
              <td>Event</td>
              <td>
                {{ auditDetails.event }}
              </td>
            </tr>
            <tr>
              <td>Message</td>
              <td>
                {{ auditDetails.message }}
              </td>
            </tr>
            <tr>
              <td>Model Name</td>
              <td>
                {{ auditDetails.auditable_type }}
              </td>
            </tr>
            <tr>
              <td>Old Value</td>
              <td>
                {{ auditDetails.old_values }}
              </td>
            </tr>
            <tr>
              <td>New Value</td>
              <td>
                {{ auditDetails.new_values }}
              </td>
            </tr>
            <tr>
              <td>Agent</td>
              <td>
                {{ auditDetails.user_agent }}
              </td>
            </tr>
            <tr>
              <td>URL</td>
              <td>
                {{ auditDetails.url }}
              </td>
            </tr>
            <tr>
              <td>Date Time</td>
              <td>
                {{ auditDetails.created_at ? showLocalDateTime(auditDetails.created_at):"N/A" }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-modal>
    <!-- table -->
    <vue-good-table
      v-if="!isLoading"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      theme="my-theme"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: SL -->
        <span v-if="props.column.field === 'sl'">{{ ++props.index }}</span>
        <span v-if="props.column.field === 'createdAt'">{{ props.row.created_at ? showLocalDateTime(props.row.created_at) : 'N/A' }}</span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
        <span v-if="props.column.field === 'action'">
          <span class="d-flex">
            <b-button
              class="btn-sm mr-1 px-1"
              variant="outline-warning"
              @click="onDetailsRow(props.row)"
            >
              Details
            </b-button>
          </span>
        </span>
      </template>

      <!--  pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <Pagination
          :v-if="props"
          :pagination-props="props"
          :refresh-grid="refreshGrid"
        />
      </template>
    </vue-good-table>
    <preloader v-if="isLoading" />
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
// import FemsToastrService from '@/@service/utils/FemsToastr.service'
import AuditService from '@/@service/api/Audit.service'

// eslint-disable-next-line no-unused-vars
import { showLocalDateTime } from '@/@service/utils/utils'

import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

import Pagination from '@/views/components/vue-good-table-pagination/Pagination.vue'

import Preloader from '../components/preloader/preloader.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    VueGoodTable,
    Pagination,
    Preloader,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      pageLength: 10,
      dir: false,
      isLoading: false,
      rows: [],
      auditDetails: {},

      columns: [
        {
          label: 'SL',
          field: 'sl',
          sortable: false,
        },
        {
          label: 'Employee Id',
          field: 'user_username',
          width: '200px',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Employee ID',
          },
          sortable: false,
        },
        {
          label: 'Event',
          field: 'event',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Event',
          },
          sortable: true,
        },
        {
          label: 'Audit Model',
          field: 'auditable_type',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Model',
          },
          sortable: true,
        },
        {
          label: 'Created At',
          field: 'createdAt',
          width: '230px',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getGridData()
  },
  methods: {
    getGridData() {
      this.isLoading = true
      AuditService.getAll().then(
        res => {
          const response = res.data
          this.isLoading = false
          this.rows = response.data
        },
      ).catch(() => {
        this.isLoading = false
      })
    },
    refreshGrid() {
      this.getGridData()
    },
    onDetailsRow(row) {
      this.auditDetails = {}
      this.$bvModal.show('audit-details')
      this.auditDetails = row
    },

  },
}
</script>
<style lang="scss">
svg.feather.feather-refresh-cw {
  height: 15px;
  width: 15px;
}
</style>
